<template>
  <div class="template-default">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.template-default {}
</style>
